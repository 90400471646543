// sass file for login

@import "~font-awesome/scss/font-awesome.scss";
$maincolor : #0081c1;

// mixins pickés de bootstrap

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}





*{
	box-sizing: border-box
}
body{
	margin:0;
	font-family: Raleway, sans-serif;
}

.container{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background: #0250c5;
	position:relative;
	z-index:1;
    background: -webkit-linear-gradient(bottom, #0250c5, #d43f8d);
    background: -o-linear-gradient(bottom, #0250c5, #d43f8d);
    background: -moz-linear-gradient(bottom, #0250c5, #d43f8d);
    background: linear-gradient(bottom, #0250c5, #d43f8d);
		&:before{
			content: "";
	    display: block;
	    position: absolute;
	    z-index: -1;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    left: 0;
	    background-image: url(/images/before.png);
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: center;
		}
}
.wrap{
	max-width: 500px;
	width:100%;
	background: #fff;
	border-radius: 3px;
	overflow: hidden;
	padding:50px;
	h1{
		font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
	}
	.wrapinput{
		position:relative;
		margin-bottom:15px;
		width:100%;
		z-index:1;
	}
	.input100{
		font-size: 18px;
		line-height: 1.2;
		color: #686868;
		display: block;
		width: 100%;
		background: #e6e6e6;
		height: 62px;
		border-radius: 3px;
		padding: 0 30px 0 65px;
		outline: none;
    border: none;
	}
	.focus-input {
	  display: block;
	  position: absolute;
	  border-radius: 3px;
	  bottom: 0;
	  left: 0;
	  z-index: -1;
	  width: 100%;
	  height: 100%;
	  box-shadow: 0px 0px 0px 0px;
	  color: $maincolor;
	}

	.input100:focus + .focus-input {
	  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
	  animation: anim-shadow 0.5s ease-in-out forwards;
		//box-shadow: 0px 0px 60px 20px;
	}
	@keyframes anim-shadow {
	  to {
	    box-shadow: 0px 0px 60px 20px;
	    opacity: 0;
	  }
	}
	.symbol {
    font-size: 24px;
    color: #999999;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 23px;
    padding-bottom: 5px;
    pointer-events: none;
    transition: all 0.4s;
	}
}
.center{
	display:block;
	text-align:center;
}
img{
	display:block;
	max-width:100%;
	height:auto;
}
a{
	font-size: 16px;
  color: #999999;
  line-height: 1.4;
}
.btn,button{
	font-size: 16px;
	cursor:pointer;
	line-height: 1.5;
	color: #fff;
	text-transform: uppercase;
	width: 100%;
	height: 62px;
	border-radius: 3px;
	background: $maincolor;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 25px;
	transition: all 0.4s;
	// pas toucher
	-webkit-appearance: none;
	appearance: none;
	outline: none !important;
    border: none;
		margin-bottom:16px;
		&:hover{
			background:#333;
		}
}

.reset-password-form-errors{
	color:red;
}
